<template>
  <div>
      <div class="header">
        <img src="../../assets/mzt/yjt_top.png" alt="" class="mzt_img"/>
        <img src="../../assets/mzt/yjt_middle.png" alt="" class="mzt_img" />
        <img src="../../assets/mzt/yjt_bottom.png" alt="" class="mzt_img"/>
      </div>
  </div>
</template>
<script>
export default {
  name: 'yjt',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.mzt_img{
  display: block;
  width: 100%;
}
</style>
